import logo from './assets/images/logo.png';

const config = {
  authentication: {
    logo,
    providers: {
      email: {
        enable: true,
      },
      saml: {
        enable: true,
        environments: {
          staging: {
            authUrl: '/users/auth/saml'
          },
          production: {
            authUrl: '/users/auth/saml'
          }
        }
      },
      developer: {
        enable: true,
        environments: {
          development: {
            authUrl: '/users/auth/developer'
          },
          staging: {
            authUrl: '/users/auth/developer'
          }
        }
      }
    },
  }
};

export default config;
