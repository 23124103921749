// @ts-nocheck
import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { ApolloProvider } from '@apollo/client';
import { ConfigProvider } from 'antd';
import en from 'antd/lib/locale/en_US';
import AntForm, { AntFormRailsNested } from "@9troisquarts/ant-form";
import Layout from './components/Layout';
import routes from "./routes";
import TranslationProvider from "../../utils/TranslationProvider";
import graphqlClient from "../../utils/graphqlClient";
import flattenRoutes from "../../utils/flattenRoutes";
import UserContext from "../../utils/UserContext";
import '../../assets/stylesheets/global.sass'
import { NtqToolbar, NtqToolbarProvider } from "@9troisquarts/wand";
// import dayjs from 'dayjs'
// import advancedFormat from 'dayjs/plugin/advancedFormat'
// import customParseFormat from 'dayjs/plugin/customParseFormat'
// import localeData from 'dayjs/plugin/localeData'
// import weekday from 'dayjs/plugin/weekday'
// import weekOfYear from 'dayjs/plugin/weekOfYear'
// import weekYear from 'dayjs/plugin/weekYear'
// import localizedFormat from 'dayjs/plugin/localizedFormat';
// import relativeTime from 'dayjs/plugin/relativeTime'

// dayjs.extend(customParseFormat)
// dayjs.extend(advancedFormat)
// dayjs.extend(weekday)
// dayjs.extend(localeData)
// dayjs.extend(weekOfYear)
// dayjs.extend(weekYear)
// dayjs.extend(localizedFormat)
// dayjs.extend(relativeTime)

AntForm.configure({
  formProps: {
    layout: 'vertical',
  },
});

AntForm.addField('nested', {
  component: AntFormRailsNested,
  showFormItemError: false,
});

type IProps = {
  user: any;
}


const isDev = process.env.NODE_ENV === "development";

const { client } = graphqlClient({ url: '/graphql' });

const App: React.FC<IProps> = props => {
  return (
    <ConfigProvider locale={en}>
      <NtqToolbarProvider enabled={isDev} impersonation>
        <UserContext.Provider value={props.user}>
          <BrowserRouter history={history}>
            <ApolloProvider client={client}>
              <NtqToolbar enabled={isDev} />
              <Layout user={props.user}>
                <Routes>
                  {flattenRoutes(routes).map(route => {
                    const Component = route.component;
                    return (
                      <Route key={route.name} exact path={route.path} element={<Component name={route.name} {...route.props || {}} />}/>
                    )
                  })}
                </Routes>
              </Layout>
            </ApolloProvider>
          </BrowserRouter>
        </UserContext.Provider>
      </NtqToolbarProvider>
    </ConfigProvider>
  )
}

export default TranslationProvider(App);
